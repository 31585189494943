import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BaseThreejsComponent } from './base-threejs/base-threejs.component';
import { BrowserModule } from '@angular/platform-browser';
import { FlexLayoutModule } from "@angular/flex-layout";
import { HttpClientModule } from '@angular/common/http';
import { ModalModule } from "./_modal";
import { NgModule } from '@angular/core';
import { ThreejsBgComponent } from './threejs-bg/threejs-bg.component';

@NgModule({
  declarations: [
    AppComponent,
    ThreejsBgComponent,
    BaseThreejsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FlexLayoutModule,
    ModalModule
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }

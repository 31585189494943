import * as THREE from 'three'

import { Component, ElementRef, HostListener } from '@angular/core';

import { BaseThreejsComponent } from '../base-threejs/base-threejs.component';
import { HttpClient } from '@angular/common/http';
import { ModalService } from '../_modal';
import {Particle} from 'src/app/threejs-bg/particle'
import {ParticlePositions} from 'src/app/threejs-bg/particle-positions'
import Stats from 'three/examples/jsm/libs/stats.module.js';
import { environment } from "../../environments/environment";

@Component({
  selector: 'app-threejs-bg',
  templateUrl: './threejs-bg.component.html',
  styleUrls: ['./threejs-bg.component.css']
})

export class ThreejsBgComponent extends BaseThreejsComponent {
  private particleCount: number = 22000
  private dummy = new THREE.Object3D()
  private plane = new THREE.PlaneGeometry(0.07, 0.07)

  private material = new THREE.MeshBasicMaterial({
    // color: 0x00ff00,
    color: "#00dfff",
    wireframe: false,
  });

  private instancedM = new THREE.InstancedMesh(this.plane, this.material, this.particleCount)
  private particleArray: Particle[] = []
  private cloudParticles: THREE.Mesh<THREE.PlaneGeometry, THREE.MeshLambertMaterial>[] = []

  private stats: Stats

  private mouse = {
    x: 0,
    y: 0,
    radius: 150
  }

  constructor(protected el:ElementRef, protected httpClient: HttpClient, private modalService : ModalService) {
    super(el, httpClient)
    this.setCanvasSelectorInsideElement("#bg-canvas")
    this.stats = Stats()

    this.instancedM.instanceMatrix.setUsage( THREE.DynamicDrawUsage )
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e : MouseEvent) {
    this.mouse.x = e.x
    this.mouse.y = e.y
  }

  update(): void {
    // Update stats if not production
    if(!environment.production){
      this.stats.update()
    }

    if(!this.modalService.isModalOpen()){
      this.updateParticlesWithMouse()
    } else {
      this.updateParticlesNoMouse()
    }

    this.cloudParticles.forEach(p => {
      p.rotation.z -= 0.001
    }
  )}

  ngOnInit(): void {
    super.ngOnInit()

    this.scene.fog = new THREE.FogExp2("#040e17", 0.01)
    this.renderer.setClearColor(this.scene.fog.color)
    let ambient = new THREE.AmbientLight(0x555555)
    this.scene.add(ambient)

    let blueLight = new THREE.PointLight(0x3677ac, 150, 450, 1.7)
    blueLight.position.set(300, 300, 200)
    this.scene.add(blueLight)

    this.httpClient.get("assets/bg-particle-positions.json", {responseType: 'json'}).toPromise().then(
      data => {
        let particlePositions  = data as ParticlePositions

        let count = 0
        for (let i = 0; i < this.particleCount; i++) {
          let x = (Math.random()) * 0.05 + particlePositions.data[count++]
          let y = (Math.random()) * 0.05 + particlePositions.data[count++]
          let z = (Math.random()) * 0.05 + particlePositions.data[count++]

          this.particleArray.push(new Particle(x, y + 1, z))
          if(count > particlePositions.data.length) count = 0
        }

        for (let i = 0; i < this.particleCount; i++){
          this.dummy.position.set(this.particleArray[i].x, this.particleArray[i].y, this.particleArray[i].z)
          this.dummy.updateMatrix()
          this.instancedM.setMatrixAt(i, this.dummy.matrix )
          // this.instancedM.setColorAt(i, new THREE.Color("27ade6"))
        }

        this.scene.add(this.instancedM)

    })

    let cloudGeo = new THREE.PlaneBufferGeometry(200, 200)

    let loader = new THREE.TextureLoader()
    loader.load("assets/smoke.png", texture =>{
      let cloudMaterial = new THREE.MeshLambertMaterial({
          map: texture,
          transparent: true
      })

      for(let p=0; p < 50; p++)
      {
          let cloud = new THREE.Mesh(cloudGeo, cloudMaterial)
          cloud.position.set(
              Math.random() * 80 - 50,
              3,
              Math.random() * 60 - 143
          )
          // cloud.rotation.x = 1.16
          // cloud.rotation.y = -0.12
          cloud.rotation.z = Math.random() * 2 * Math.PI
          cloud.material.opacity = 0.55
          cloud.frustumCulled = false
          this.cloudParticles.push(cloud)
          this.scene.add(cloud)
      }
    })

    // Add stats if not production
    if(!environment.production){
      document.body.appendChild(this.stats.dom);
    }
  }

  updateParticlesWithMouse(){
    var vector = new THREE.Vector3()
    var widthHalf = 0.5 * this.containerWidth
    var heightHalf = 0.5 * this.containerHeight

    for (let i = 0; i < this.particleCount; i++) {
      if (this.particleArray[i] !== undefined) {
        this.dummy.position.set(this.particleArray[i].x, this.particleArray[i].y, 0)
        this.dummy.updateMatrixWorld()
        vector.setFromMatrixPosition(this.dummy.matrixWorld)
        vector.project(this.camera)

        vector.x = ( vector.x * widthHalf ) + widthHalf
        vector.y = - ( vector.y * heightHalf ) + heightHalf
        let dx = this.mouse.x - vector.x
        let dy = this.mouse.y - vector.y

        let distance = Math.sqrt(dx * dx + dy * dy)
        let forceDircetionX = dx / distance
        let forceDirectionY = dy / distance
        let maxDistance = this.mouse.radius
        let force = (maxDistance - distance) / maxDistance
        let directionX = forceDircetionX * force * this.particleArray[i].density
        let directionY = forceDirectionY * force * this.particleArray[i].density

        if (distance < this.mouse.radius) {
          // this.instancedM.setColorAt(i, new THREE.Color("#27ade6").lerp(new THREE.Color("#27e65a"), maxDistance/distance))
          this.particleArray[i].x -= directionX * 0.01
          this.particleArray[i].y += directionY * 0.01
          this.dummy.position.set(this.particleArray[i].x, this.particleArray[i].y, 0)
        } else {
          // this.instancedM.setColorAt(i, new THREE.Color("#27ade6"))
          if(this.particleArray[i].x !== this.particleArray[i].baseX) {
            let _dx = this.particleArray[i].x - this.particleArray[i].baseX
            this.particleArray[i].x -= _dx/20
          }
          if(this.particleArray[i].y !== this.particleArray[i].baseY) {
            let _dy = this.particleArray[i].y - this.particleArray[i].baseY
            this.particleArray[i].y -= _dy/20
          }
        }
        this.dummy.updateMatrix()
        this.instancedM.setMatrixAt(i, this.dummy.matrix)
      }
    }

    if(this.instancedM.instanceColor != null) this.instancedM.instanceColor.needsUpdate = true
    if(this.instancedM.instanceMatrix != null) this.instancedM.instanceMatrix.needsUpdate = true
  }

  updateParticlesNoMouse() {
    var vector = new THREE.Vector3()
    var widthHalf = 0.5 * this.containerWidth
    var heightHalf = 0.5 * this.containerHeight

    for (let i = 0; i < this.particleCount; i++) {
      if (this.particleArray[i] !== undefined) {
        this.dummy.position.set(this.particleArray[i].x, this.particleArray[i].y, 0)
        this.dummy.updateMatrixWorld()
        vector.setFromMatrixPosition(this.dummy.matrixWorld)
        vector.project(this.camera)

        vector.x = ( vector.x * widthHalf ) + widthHalf
        vector.y = - ( vector.y * heightHalf ) + heightHalf
        let dx = widthHalf - vector.x
        let dy = heightHalf - vector.y

        let distance = Math.sqrt(dx * dx + dy * dy)
        let forceDircetionX = dx / distance
        let forceDirectionY = dy / distance
        let forceX = 0
        let forceY = 0

        let maxDistance = widthHalf > heightHalf ? widthHalf : heightHalf
        if( distance < maxDistance){
          forceX = 200 / (widthHalf - distance)
          forceY = 200 / (heightHalf - distance)
        } else if (distance < maxDistance * 2) {
          forceX = 1
          forceY = 1
        } else {
          forceX = 0
          forceY = 0
        }

        let directionX = forceDircetionX * forceX * this.particleArray[i].density
        let directionY = forceDirectionY * forceY * this.particleArray[i].density

        this.particleArray[i].x -= directionX * 0.05
        this.particleArray[i].y += directionY * 0.05
        this.dummy.position.set(this.particleArray[i].x, this.particleArray[i].y, 0)

        this.dummy.updateMatrix()
        this.instancedM.setMatrixAt(i, this.dummy.matrix)
      }
    }

    if(this.instancedM.instanceColor != null) this.instancedM.instanceColor.needsUpdate = true
    if(this.instancedM.instanceMatrix != null) this.instancedM.instanceMatrix.needsUpdate = true
  }
}


import {
  ArcElement,
  BarController,
  BarElement,
  BubbleController,
  CategoryScale,
  Chart,
  Decimation,
  DoughnutController,
  Filler,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  LogarithmicScale,
  PieController,
  PointElement,
  PolarAreaController,
  RadarController,
  RadialLinearScale,
  ScatterController,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip
} from 'chart.js';
import { Component, ElementRef } from "@angular/core";

import { ModalComponent } from "./modal.component";
import { ModalService } from "./modal.service";

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
);


@Component({
  selector: 'modal-skills',
  templateUrl: 'modal-skills.component.html',
  styleUrls: ['modal.component.css'],
})
export class ModalSkillsComponent extends ModalComponent {
  private color_cyan = 'rgba(0, 223, 255, 1)'
  private color_cyan_transparent = 'rgba(0, 223, 255, 0.2)'

  private lang_c_sharp = 'C#'
  private lang_c_plus_plus = 'C++'
  private lang_java = 'Java'
  private lang_python = 'Python'
  private lang_javascript = 'JavaScript'
  private lang_typescript = 'TypeScript'

  private tooltip_c_sharp =
`Three years of writing and maintaining enterprise level nUnit framework.
Got recognition for innovative automation strategies.
Proved that it's possible to build API automation with nUnit and RestSharp
and save money on ReadyAPI license as well as benefit from UI test performance
improvements by reusing API endpoints in preconditions and dispose.
I like to go a step further then Page Object Model and with the help of generics
reduce maintenance overhead to a minimum.

Aside from work I study how to build back-end with .NET and fiddle around in Unity game engine.

To reach next level I need to get more practice with Entity Framework, MVC and software security.
At work I still experience difficulties understanding Stored Procedures in developer's code.
Not ready to develop enterprise applications.`

  private tooltip_c_plus_plus =
`By far my most favorite language.
Ability to visualize code is my favorite activity so I use C++ in conjunction with OpenGL.
For several years I've tried to build a 3D Editor completely rebuilding it as my knowledge expands.
This language truly has no limits and it helps me understand other languages and patterns.
Loki library offers good mental exercise.

It's hard to self evaluate C++ skill level.
The amount of information one has to know has no end in sight.
In the near future I want to learn memory management and build MVC from scratch to understand it better.`

  private tooltip_java =
`Before C# I used to do automation with Java and JUnit for 3 years.
Nothing as fancy as I do now, just UI automation against single stage environment.
In a spare time I used to study Android app development using Java with OpenGL integration.
Published one app to the google app store.

Not growing developer skills in this area.
For android app development I would use Kotlin as recommended by Google.
And for back-end I prefer .NET instead of Java EE.`

  private tooltip_python =
`Writing automated tests using PyTest framework.
Creating internal tools using python.
Used Python to aggregate data parsed from TestRail to help identify coverage gaps.
Wrote a plugin for SoftImage XSI.`

  private tooltip_javascript =
`Using on the basic level inside selenium script executioner to help
automate actions that cannot be automated otherwise,
like endless scroll, drag-and-drop.
Studied how event system works. Completed tutorials on WebGL.`

  private tooltip_typescript =
`Studying as a part of Angular tutorials.
This website is build with Angular; Chart.JS and ThreeJS libraries.`

  constructor(public modalService: ModalService, protected el: ElementRef) {
    super(modalService, el)
  }

  ngOnInit(): void {
    super.ngOnInit()

    var myChart = new Chart("skills-chart", {
      type: 'bar',
      data: {
          labels: [
            this.lang_python,
            this.lang_c_sharp,
            this.lang_java,
            this.lang_c_plus_plus,
            this.lang_javascript,
            this.lang_typescript],
          datasets: [{
              label: 'Level',
              data: [3, 3, 2, 2, 1, 1],
              backgroundColor: [
                this.color_cyan_transparent,
              ],
              borderColor: [
                this.color_cyan,
              ],
              borderWidth: 1
          }]
      },
      options: {
        responsive:true,
        maintainAspectRatio: true,
        scales: {
          x: {
            ticks: {
              color: this.color_cyan,
            },
            grid: {
              color: this.color_cyan_transparent
            },
          },
          y: {
            min: 0,
            max: 5,
            ticks: {
              callback: function(value) {
                  switch (value) {
                    case 0:
                      return 'Never Tried'
                    case 1:
                      return 'Beginner'
                    case 2:
                      return 'Intermediate'
                    case 3:
                      return 'Advanced'
                    case 4:
                      return 'Pro'
                    case 5:
                      return 'Bjarne Stroustrup'
                    default:
                      return ''
                  }
              },
              color: this.color_cyan,
            },
            grid: {
              color: this.color_cyan_transparent
            }
          }
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            callbacks: {
              footer: (tooltipItems) => {
                let label = ''
                tooltipItems.forEach(function(tooltipItem) {
                  label = tooltipItem.label;
                });
                switch (label) {
                  case this.lang_c_sharp:
                    return this.tooltip_c_sharp
                  case this.lang_c_plus_plus:
                    return this.tooltip_c_plus_plus
                  case this.lang_java:
                    return this.tooltip_java
                  case this.lang_javascript:
                    return this.tooltip_javascript
                  case this.lang_python:
                    return this.tooltip_python
                  case this.lang_typescript:
                    return this.tooltip_typescript
                  default:
                    return ''
                }
              }
            }
          }
        }
      }
    });
  }

}

import { Injectable } from '@angular/core'

// import { ThreejsBgService } from '../threejs-bg/threejs-bg.service';

@Injectable({providedIn: 'root'})
export class ModalService {
  private modals: any[] = []
  private isOpen: boolean = false

  constructor() { }

  add(modal: any) {
    this.modals.push(modal)
  }

  remove(id: string) {
    this.modals = this.modals.filter(x => x.id !== id)
  }

  open(id: string) {
    this.modals.forEach(m => m.close());
    const modal = this.modals.find(x => x.id === id)
    modal.open()
    this.isOpen = true
  }

  close() {
    this.modals.forEach(m => m.close());
    this.isOpen = false
  }

  isModalOpen() : boolean {
    return this.isOpen
  }
}

export class Particle {
  public x:number
  public y:number
  public z:number
  public baseX:number
  public baseY:number
  public baseZ:number
  public density: number
  constructor(x:number, y: number, z:number) {
    this.x = x
    this.y = y
    this.z = z
    this.baseX = x
    this.baseY = y
    this.baseZ = z
    this.density = (Math.random() * 15) + 10
  }
}

import * as THREE from 'three'

import { Component, ElementRef, HostListener, OnInit } from '@angular/core';

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { HttpClient } from "@angular/common/http";
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';

@Component({
  selector: 'app-base-threejs',
  template: ``,
  styles: []
})

/**
 * Starting class to build THREE JS canvas.
 * Sets up scene, camera, renderer and starts animation loop.
 * Adjusts canvas dimensions based on resized window.
 */
export class BaseThreejsComponent implements OnInit {
  protected containerWidth:number = 0
  protected containerHeight: number = 0
  protected scene: THREE.Scene
  protected camera: THREE.PerspectiveCamera
  protected renderer: THREE.WebGLRenderer
  protected composer: EffectComposer

  private _canvasSelector:String = ''

  constructor(protected el:ElementRef, protected httpClient: HttpClient) {
    this.scene = new THREE.Scene()
    this.camera = new THREE.PerspectiveCamera(75, 1, 0.1, 100)
    this.renderer = new THREE.WebGLRenderer();
    this.composer = new EffectComposer(this.renderer)
  }

  ngOnInit(): void {
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.el.nativeElement.querySelector(this._canvasSelector),
      antialias: true
    });
    this.changeSize()
    this.composer = new EffectComposer(this.renderer)
    this.composer.addPass(new RenderPass(this.scene, this.camera))

    var bloomPass = new UnrealBloomPass( new THREE.Vector2( this.containerWidth, this.containerHeight ), 0.5, 0.1, 0.55 )
    this.composer.addPass(bloomPass)

    this.animate()
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.changeSize()
  }

  /**
   * Resize camera and render target based on new element dimensions.
   */
  changeSize(){
    this.containerWidth = this.el.nativeElement.offsetWidth
    this.containerHeight = this.el.nativeElement.offsetHeight
    let aspect = this.containerWidth / this.containerHeight
    this.camera.aspect = aspect
    this.camera.position.z = 16 / Math.sqrt(aspect)

    this.camera.updateProjectionMatrix()
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(this.containerWidth, this.containerHeight);
  }

  /**
   * Start render loop
   */
  animate()  {
    requestAnimationFrame(() => this.animate())

    this.update()
    this.composer.render();
  }

  /**
   * Update calculations
   */
  update() {

  }

  /**
   * Set canvas selector so that Renderer can send its animation to it.
   * @param cssSelector selector for canvas inside the element.
   */
  setCanvasSelectorInsideElement(cssSelector: String){
    this._canvasSelector = cssSelector
  }
}

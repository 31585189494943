import { CommonModule } from "@angular/common";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ModalComponent } from "./modal.component";
import { ModalSkillsComponent } from "./modal-skills.component";
import { NgModule } from "@angular/core";

@NgModule({
  imports: [ CommonModule, FlexLayoutModule ],
  declarations: [ModalComponent, ModalSkillsComponent],
  exports: [ModalComponent, ModalSkillsComponent]
})
export class ModalModule { }

import { Component, ElementRef, Input, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";

import { ModalService } from "./modal.service";

@Component({
  selector: 'modal',
  templateUrl: 'modal.component.html',
  styleUrls: ['modal.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ModalComponent implements OnInit, OnDestroy {
  @Input() id: string = ''
  protected element: any

  constructor(public modalService: ModalService, protected el: ElementRef) {
    this.element = el.nativeElement
  }

  ngOnInit(): void {
    if (!this.id) {
      console.log('modal must have an id')
      return
    }

    this.element.style.display = 'none'

    document.body.appendChild(this.element)

    this.modalService.add(this)
  }

  ngOnDestroy(): void {
    this.modalService.remove(this.id)
    this.element.remove()
  }

  open() : void {
    this.element.style.display = 'block'
  }

  close() : void {
    this.element.style.display = 'none'
  }
}
